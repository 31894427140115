'use client';
import React from 'react';
import { signOut } from 'next-auth/react';
import { useSession } from 'next-auth/react';
import Image from 'next/image';

import Link from 'next/link';
// react componant that takes children 
const Sidebar = ({ children }) => {
  const { status, data: session } = useSession();
  const toggleMobileSearch = () => {
    var searchDropdown = document.getElementById('searchDropdown');
    searchDropdown.classList.toggle('hidden');
  }

  return (
    <div className="drawer lg:drawer-open">
    
  <label htmlFor="my-drawer-2" className="btn btn-primary drawer-button lg:hidden">
        O 
  </label>
  <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
  
    <div className="drawer-content flex flex-col p-4 bg-white text-black ">
      {children}

     
  </div>
  <div className="drawer-side">
    <label htmlFor="my-drawer-2" aria-label="close sidebar" className="drawer-overlay"></label>
    <ul className="menu bg-base-200 text-base-content min-h-full w-80 p-4">
      {/* Sidebar content here */}
      <li><Link href='/' className='btn btn-ghost text-xl'>
          Home
        </Link></li>

        {status === 'authenticated' && ( 
       <li><Link href='/people' className='btn btn-ghost text-xl'>
         People
        </Link> </li> 
        
      )}

{status === 'authenticated' && ( 
       <li><Link href='/people/all' className='btn btn-ghost text-xl'>
         All People
        </Link> </li> 
        
      )}
      {status === 'authenticated' && ( 
      <li><Link href='/people/cards' className='btn btn-ghost text-lg text-blue-500'>
           All Cards
          </Link> </li> 
        
      )}

{status === 'authenticated' && ( 
      <li><Link href='/people/bbcc' className='btn btn-ghost text-lg text-blue-500'>
          BBCC
          </Link> </li> 
        
      )}

{status === 'authenticated' && ( 
      <li><Link href='/people/goshen' className='btn btn-ghost text-lg text-blue-500'>
          Goshen
          </Link> </li> 
        
        
      )}

{status === 'authenticated' && ( 
      <li><Link href='/people/personal' className='btn btn-ghost text-lg text-blue-500'>
          Personal
          </Link> </li> 
        
      )}
{status === 'authenticated' && ( 
  <li><Link href='/config' className='btn btn-ghost text-xl'>
       Config
     </Link> </li> 


       
      )}

      <li><a>Sidebar Item 2</a></li>
    </ul>

    {status === 'authenticated' && (
          <div className='dropdown dropdown-end'>
            <div
              tabIndex={0}
              role='button'
              className='btn btn-ghost btn-circle avatar'
            >
              <div className='w-10 rounded-full'>
                <Image
                  alt='Profile Picture'
                  src={session.user.image ?? '/default-user.png'}
                  width={60}
                  height={60}
                />
              </div>
            </div>
            <ul
              tabIndex={0}
              className='mt-3 z-[1] p-2 shadow menu menu-sm dropdown-content bg-base-100 rounded-box w-52'
            >
              <li>
                <Link href='/profile'>Profile</Link>
              </li>
              <li>
                <Link href='/events/create'>Create Event</Link>
              </li>
              <li>
                <Link href='' onClick={signOut}>
                  Sign Out
                </Link>
              </li>
            </ul>
          </div>
        )}
  </div>
</div>
  );
};

export default Sidebar;




    {/* Page content here */}
   